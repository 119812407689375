.chat-container {
	position: relative;
	height: calc(100% - 262px);
	@media(max-width: 767px) {
		height: calc(100% - 200px);
	}
	.active {
		background-color: $color-white !important;
	}
	input {
		height: unset;
	}
	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	  color: $color-placeholder;
	  opacity: 1; /* Firefox */
	}

	:-ms-input-placeholder { /* Internet Explorer 10-11 */
	  color: $color-placeholder;
	}

	::-ms-input-placeholder { /* Microsoft Edge */
	  color: $color-placeholder;
	}
	.str-chat {
		height: 100%;
	}
	.rfu-file-upload-button svg,
	.str-chat__input-emojiselect {
		fill: $color-grey;
	}
}
.chat-list-search {
	border-bottom: 1px solid rgba(235, 235, 235, 0.5);
}
.chat-list-header {
	.nav-link {
		flex: 0 0 50%;
		border-bottom: 4px solid transparent;
		&.active {
			color: $color-light-blue;
			border-bottom-color: $color-light-blue;
		}
		&:hover {
			color: $color-light-blue;
		}
	}
}
.chat-list-content {
	&.tab-content>.active {
		display: flex;
	}
}
.user-icon {
	flex: 0 0 50px;
	width: 50px;
	height: 50px;
	& > div {
		height: 100%;
		width: 100%;
	}
}
.chat-time {
	margin-top: 2px;
}
.chat-item {
	cursor: pointer;
	@include transition(0.2s ease);
	border-bottom: 1px solid rgba(235, 235, 235, 0.5);
	&:hover,
	&.active-chat {
		background-color: rgba(76, 202, 252, 0.04);
	}
}
.user-status {
	position: absolute;
	top: 0;
	right: 0;
	height: 15px;
	width: 15px;
	border: 3px solid $color-white;
	@include border-radius(50%);
	&.online {
		background-color: #43D881;
	}
	&.offline {
		background-color: #ECECEC;
	}
}
.msg-wrapper {
	a:hover {
		color: $color-light-blue;
	}
}
.single-msg {
	margin-bottom: 4rem;
	p {
		color: #1D2733;
	}
	.user-icon {
		@include box-shadow(5px 3px 30px #DFDFE4);
		@media(max-width: 767px) {
			flex: 0 0 20px;
			width: 20px;
			height: 20px;
			img {
				width: 100%;
			}
		}
	}
	@media(max-width: 767px) {
		margin-bottom: 1.5rem;
		font-size: 0.825rem;
		.msg-time {
			font-size: 12px;
		}
	}
}
.msg-text {
	max-width: 60%;
	@media(max-width: 991px) {
		max-width: 75%;
	} 
}
.msg-received .msg-text {
	@include border-radius(25px 25px 25px 0);
	background-color: rgba(76, 202, 252, 0.10);
}
.msg-sent .msg-text {
	@include border-radius(25px 25px 0 25px);
	background-color: rgba(186, 187, 196, 0.10);
}

// chat sidebar ipad/mobile view
.chat-aside-toggle {
	position: absolute;
	right: -30px;
	top: 50px;
	background-color: $color-light-blue;
	color: $color-white;
	height: 40px;
	width: 30px;
	font-size: 14px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	@include box-shadow(0 10px 20px rgba(0, 0, 0, 0.14));
}
@media(max-width: 991px) {
	.chat-list-wrap {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		padding: 0;
		z-index: 1;
		width: 85%;
		max-width: 350px;
		@include transform(translateX(-100%));
		@include transition(transform 0.2s linear);
		.card {
			@include border-radius(0);
		}
	}
	.chat-list-aside {
		@include transform(translateX(0));
	}
}