.rating-count {
    font-size: 24px;
    font-family: Poppins-SemiBold;
    background-color: rgba(76, 202, 252, 0.04);
}
.aside-card .card-body {
    max-height: 360px;
    overflow: auto;
}
.poll-cell {
    position: relative;
    overflow: hidden;
    height: 60px;
    background-color: #F9F9FC;
    @include border-radius(8px);
}
.poll-cell-options .btn {
    @include border-radius(8px);
}
.poll-cell-details {
    opacity: 0;
}
.poll-cell-percent {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    @include border-radius(8px);
    @include transition(width 0.3s ease);
}
.poll-cell-text {
    width: 100%;
    span {
        display: block;
        line-height: 1.2;
    }
}
.aside-card .aside-poll-card {
    max-height: unset;
}
.active-sort {
    color: $color-black2;
}
.single-comment {
    &:last-child .border-bottom {
        border: 0 !important;
    }
}
.comment-box {
    input {
        @include border-radius(23px);
        height: 54px;
        background-color: #F9F9FC;
    }
}