.home-welcome-banner {
	background-color: $color-light-blue;
	@include border-radius(16px);
	h5 {
		font-size: 22px;
		font-family: Poppins-SemiBold;
	}
	.btn {
		width: 148px;
		height: 44px;
		border: 0;
		@include border-radius(8px);
		background-color: rgba(255, 255, 255, 0.34);
		&:hover,
		&:active,
		&:focus {
			background-color: rgba(255, 255, 255, 0.24);
		}
	}
}
.rating-starts {
	i {
		color: #FDCC0D;
	}
}
.news-nav {
	button {
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.10);
		color: $color-light-blue;
		background-color: $color-white;
		height: 38px;
		width: 38px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		&:hover {
			background-color: $color-light-blue;
			color: white;
		}
		&:after {
			display: none;
		}
		&:focus {
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.10);
		}
		&.swiper-button-disabled,
		&.swiper-button-disabled.btn:not(:disabled):not(.disabled) {
			opacity: 0.5;
			color: $color-light-blue;
			background-color: $color-white;
			cursor: not-allowed;
		}
	}
}
.event-img {
	height: 150px;
	width: 150px;
	flex: 0 0 150px;
	@include border-radius(10px);
	@media(max-width: 991px) {
		height: 70px;
		width: 70px;
		flex: 0 0 70px;
	}
}
.swiper-container-multirow-column > .swiper-wrapper {
	flex-direction: unset;
}