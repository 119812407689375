.has-search .form-control {
  padding-left: 2.375rem;
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.ls-booking-filter-icon{
  padding:.61rem 15px;
}
.ls-btn-booking{
  width:100%!important;
  height:auto!important;
  padding-top:.6rem;
  padding-bottom:.6rem;
}

.ls-nav-tabs{
  border:none;
  a{
    color: $color-grey;
    border: none !important;
    &:hover{
      border:none;
    }
  }
  a.active{
    background:none!important;
    border-color:transparent!important;
    border-bottom: 3px solid $color-blue-menu!important;
    outline:none;
    color:$color-blue-menu !important;
  }
}
.ls-nav-tabs-pill {
  a {
    color: $color-grey;
    padding-top: 12px;
    padding-bottom: 12px;
    font-family: Poppins-Medium;
    &:hover,
    &.active-nav {
      background-color: $color-light-blue;
      color: #fff;
      @extend .lS-card-shadow;
    }
  }
}
.ls-tab-content{
  .active{
    background:$color-white !important;
    padding:1rem;
    border-radius: 5px;
  }
}
.ls-booking-table{
  .ls-table-image{
    width:45px;
    height:45px;
  }
  td{
    vertical-align: middle;
  }
}