body {
  background: #f9f9fc;
}

// Import colors
@import "colors.scss";
// Import colors
@import "mixins.scss";
// Import Common code
@import "basic.scss";
// Import header code
@import "header.scss";
// Import Networks code
@import "networks.scss";
// Import trainings code
@import "trainings.scss";
// Import placements code
@import "placements.scss";
// Import Login code
@import "login.scss";
// Import Profile code
@import "profile.scss";
// Import Home code
@import "home.scss";
// Import Calendar code
@import "calendar.scss";
// Import Chat code
@import "chat.scss";
// Import Guideline Code
@import "guideline.scss";
// Import Competencies Code
@import "competencies.scss";
// Import My Booking Code
@import "myBooking.scss";
// Import event Code
@import "events.scss";
// Import Team Code
@import "team.scss";
// Import Forums Code
@import "forums.scss";
// Import Filters Code
@import "filters.scss";
// Import Radio/Checkbox Code
@import "input-check.scss";
// Import Reports Code
@import "reports.scss";
// Import Pathway Code
@import "pathway.scss";
// Import Filter Animation Code
@import "filter-animation.scss";
// Import Register Code
@import "register.scss";
