main {
  margin-left: 30px;
  margin-right: 30px;
}

.rcorners3 {
  border-radius: 10px;
  background:white;
  padding: 20px;
  box-shadow: 0 8px 6px -6px lightgray;
  text-align: center;
  height: 100%;
  border: 3px solid $color-white;
  img {
    width: 100%;
    max-width: 200px;
  }
  &:hover {
    border-color: $color-light-blue;
  }
}

.section-1 {
  border-radius: 10px;
  background:white;
  margin: 10px 30px;
  font-size: 10px;
  color: #aaa;
  box-shadow: 0 8px 6px -6px lightgray;
  span {
    font-size: 25px;
    font-weight: bold;
    color: black;
  }
  #search-form {
    .serach-icon {
      width: 2.375rem;
      line-height: 2.375rem;
      text-align: center;
      pointer-events: none;
      color: #aaa;
      font-size: 11px;
      margin-top: -2px;
      margin-right: -14px;
      font-weight: bold;
    }
    .search-input {
      font-size: 10px;
      margin: 2px;
    }
    .search-input:focus {
      outline: 0;
      box-shadow: none;
    }
    .search-bar {
      border: 2px solid lightgrey;
      border-radius: 5px;
      input {
        border: 0
      }
    }
    .search-panel {
      width: 20%;
    }
    .search-panel::before {
      content: '';
      border-left: 1px solid #d2d7da;
      padding-bottom: 6px
    }
    .dropdown-toggle::after {
      display: none !important;
    }

    .search-panel-dropdown .fas { 
      margin: 0px 6px;
      font-weight: bold;
      font-size: 13px;
    } 
    .search-panel-dropdown {
      font-size: 10px;
      margin-top: 4px;
      color: #aaa;
      margin-left: 5px;
    }
    .search-panel-dropdown:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}

.section-3 .rcorners3:hover  {
    background-color: #4ccafc;
    color: white;
}
.active-map .rcorners3 {
  border-color: $color-light-blue;
}

.active-col {
  background-color: $color-light-blue;
  border-color: $color-light-blue;
  color: $color-white;
}

.timing-chart {
  label {
    flex: 0 0 120px;
  }
}

@media(min-width: 1399px) {
  .custom-flex-xl {
    flex: 0 0 auto;
    max-width: calc(100% - 450px);
  }
  .custom-flex-sm {
    width: 450px;
    flex: 0 0 450px;
    max-width: unset;
  }
}
@media(max-width: 1199px) and (min-width: 992px) {
  .custom-flex-xl {
    flex: 0 0 auto;
    max-width: calc(100% - 380px);
  }
  .custom-flex-sm {
    width: 380px;
    flex: 0 0 380px;
    max-width: unset;
  }
}

// .network-thumb {
//   @media(max-width: 1200px) and (min-width: 992px) {
//     width: 200px;
//     min-height: 100px;
//     flex: 0 0 200px;
//   }
// }

.img-overlayed-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}