@font-face {
  font-family: Antonio-Bold;
  src: url('../fonts/Antonio-Bold.ttf');
}

.pathway-tab {
    position: relative;
    overflow: hidden;
    &:before {
        position: absolute;
        content: "";
        left:0;
        top: 0;
        bottom: 0;
        width: 0;
        opacity: 0;
        @include transition(0.3s ease);
        background-color: $color-light-blue;
    }
    &:hover:before {
        opacity: 1;
        width: 10px;
    }
}
.active-pathway:before {
    opacity: 1;
    width: 10px;
}

.steps-info-block {
    @media(min-width: 992px) {
        width: 20%;
        flex: 0 0 20%;
    }
    @media(max-width: 1199px) {
        .step-info h4 {
            font-size: 1.2rem;
        }
    }
}
.step-count {
    img {
        width: 100%;
        @media(max-width: 991px) {
            width: 60%;
        }
    }
}