.ls-profile-image-container{
  position: relative;
  margin: 0 auto;
  max-height: 260px;
  height: 100%;
  width: 100%;
  @include transform(translateY(-45px));
  @media(max-width: 992px){
    width:260px;
    height:auto;
    @include transform(translateY(0));
  }
  @media(max-width: 768px){
    width:100%;
    height:auto;
    max-height: 200px;
    overflow: hidden;
  }
}

.ls-profile-form .upload-file{
  position: relative;
}
.ls-profile-form .upload-file input{
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.ls-profile-form .upload-file .dotted-label-field {
  height: 100%;
  max-height: 180px;
  margin-bottom: 15px;
  font-size: 14px;
  color: rgb(73, 80, 87);
  background:#F9F9FC;
  border: 1px dashed #e6e6e6;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @include border-radius(10px);
  overflow: hidden;
  @media(max-width: 992px){
    height:100%!important;
  }
}
.ls-profile-container{
  position: relative;
  width:100%;
  height: 370px;
  @media(max-width: 1200px){
    height:200px;
  }
}

.ls-image-text-container{
  position: absolute;
  width: 100%;
  padding: .5rem .5rem 0;
  bottom: -35px;
  z-index: 1;
  @media(max-width:577px){
    bottom:-27px;
  }
  h5{
    display: block;
    margin: 0 auto;
    width: 85%;
    text-align: center;
    background: #fff;
    padding: 1.7rem 1rem;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0,0,0,.4);
    @media(max-width: 991px){
      font-size: 16px;
      width: 90%;
    }
    @media(max-width: 576px){
      padding: 1rem;
    }
  }
}

// Media Queries for Profile Page
@media(max-width: 992px){
  .ls-profile-form{
    margin-bottom:1rem;
  }
}
@media(max-width: 768px){
  .ls-profile-form{
    margin-top:1rem;
  }
}
.upload-attach-col {
  background-color: #F9F9FC;
  border-color: #F4F4F6;
  border-radius: 6px;
  -ms-border-radius: 6px;
  font-size: 18px;
  font-family: Poppins-Medium;
  padding: 1rem;
}

.hover-image-text {
  display: none;
  margin: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $color-light-blue;
  color: $color-white;
  cursor: pointer;
  @include border-radius(10px);
}
.img-hover-wrap:hover {
  .hover-image-text {
    display: flex;
  }
}
.table-fixed {
  table-layout: fixed;
}
.ls-select-image{
  width:30px;
  height:30px;
  object-fit: cover;
  border-radius:50%;
}