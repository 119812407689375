.ls-login-wrapper{
  position:absolute;
  top:0;
  left:0;
  width: 100%;
  height:100%;
}
// .active{
//   background-color: #78F9C1!important;
// }
.ls-login-background{
  position:relative;
  width:100%;
  min-height:100vh;
  background:url('../images/login/backgroundImage.jpeg') no-repeat 100% 100%;
  background-size: cover;
}
.ls-login{
  width:80%;
  min-height:60vh;
  margin: 0 auto;
  border-radius: 7px;
  overflow:hidden;
  padding:.5rem 0;
  background:#fff;
  .custom-control-label{
    font-size: 12px;
    color: #808080;
    line-height: 20px;
  }
  .custom-control-label::before{
    top: 3px;
  }
  .forgot-password{
    font-size: 12px;
    color: #808080;
    line-height: 20px;
    text-decoration: none;
  }
  a, a:hover, a:active, a:link, a:visited{
    text-decoration: none;
    color:$color-black;
  }
}
.ls-form-content{
  width:75%;
}
.ls-register-form-content{
  width:85%;
}
.ls-slider-logo{
  width:70px;
  height:70px;
  border-radius:10px;
  object-fit: cover;
  margin-bottom: 9vh!important;
}
.ls-login-form{
  background:#F9F9FC;
  padding:.5rem 1rem;
  border-radius:10px;
  input{
    background: transparent!important;
    border: none;
    color:#000!important;
    padding-left: 0;
    padding-top: 0;
  }
  input::placeholder {
    color: #000;
    opacity: 1; /* Firefox */
  }
  
  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #000;
  }
  
  input::-ms-input-placeholder { /* Microsoft Edge */
   color: #000;
  }
}
.ls-login-button,.ls-login-button:hover, .ls-login-button:active,.ls-login-button:focus{
  background: $color-login-blue!important;
  box-shadow: none!important;
  border:none!important;
}


// Media Querie for login page
@media(max-width: '992px'){
  .ls-login-button, .ls-form-content{
    width:100%;
  }
}
@media(max-width: '768px'){
  .ls-form-content{
    width:100%;
  }
  .ls-login{
    margin:3rem auto;
  }
  .ls-login-slider{
    min-height:50vh;
  }
}
.ls-slider-outer {
  // max-height: 600px;
  .carousel-inner {
    height: 100%;
    @include border-radius(10px);
  }
  @media(max-width: 991px) {
    height: 300px !important;
  }
  .carousel-item {
    &.active {
      background: transparent !important;
    }
  }
}