header {
  .logo {
    width: 92px;
    @media(max-width: 1199px) {
      width: 75px;
    }
    @media(max-width: 576px) {
      width: 60px;
    }
  }
  .dropdown-toggle:after {
    display: none;
  }
  .user-info {
    .profile-img {
      position: relative;
      cursor: pointer;
      img {
        width: 54px;
        height: 54px;
        object-fit: cover;
        border-radius: 50px;
        margin-right: 5px;
        @media(max-width: 1199px) {
          width: 45px;
          height: 45px;
        }
        @media(max-width: 576px) {
          width: 35px;
          height: 35px;
        }
      }
      &:after {
        display: block;
        content: "...";
        color: #fff;
        line-height: 12px;
        position: absolute;
        height: 25px;
        width: 25px;
        border: 3px solid white;
        background-color: $color-blue;
        bottom: -10px;
        right: 0;
        text-align: center;
        border-radius: 50%;
      }
    }
    .user-name {
      p {
        color: #1D3889;
      }
      h6 {
        color: #1D2733;
        font-weight: normal;
      }
    }
  }
  .top-header-icon {
    position: relative;
    color: $color-grey;
    cursor: pointer;
    i {
      font-size: 25px;
      @media(max-width: 576px) {
        font-size: 18px;
      }
    }
    .notification-count {
      content: " ";
      color: #fff;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      position: absolute;
      height: 18px;
      width: 18px;
      background-color: $color-red;
      top: -10px;
      right: -8px;
      border-radius: 50%;
    }
  }

  // nav bar
  .main-nav {
    list-style: none;
    & > li {
      border-left: 1px solid #ECEDEE;
      & > a,
      &.more-menu .dropdown-toggle {
        color: $color-black2;
        font-family: Poppins-Medium;
        text-decoration: none;
        border-bottom: 3px solid transparent;
        padding-bottom: 10px;
        transition: all .2s ease-in-out;
        &:hover {
          border-color: $color-blue-menu;
        }
      }
      &:first-child {
        border-left: 0;
      }
      a.active {
        border-color: $color-blue-menu;
        background-color: $color-white !important;
      }
      &.d-xl-block {
        @media(max-width: 1499px) {
          display: none !important;
        }
      }
    }
  }
}
/*dropdown*/
.ls-dropdown-style {
  border: 0;
  min-width: 330px;
  padding: 0;
  @include border-radius(25px);
  .ls-dropdown-outer {
    padding: 8px 0;
    background-color: $color-white;
    @include box-shadow(0 11px 37px rgba(0, 0, 0, 0.10));
    @include border-radius(25px);
    overflow: hidden;
  }
  .ls-dropdown-inner {
    max-height: 300px;
    overflow: auto;
  }
  a {
    padding: 0 2rem;
    color: $color-black2;
    span.d-block {
      padding: 1rem 0;
    }
  }
  &:before {
    position: absolute;
    content: "";
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid $color-white;
    top: -20px;
    right: 30px;
  }
  .dropdown-item {
    &.active,
    &:active {
      color: $color-black2;
      background-color: $color-white;
    }
    &.active {
      color: $color-light-blue;
      background-color: #f8f9fa;
    }
    &:focus {
      outline: 0;
    }
  }
  @media(max-width: 767px) {
    min-width: 200px;
    a {
      padding: 0 1rem;
      font-size: 14px;
      span.d-block {
        padding: 0.725rem 0;
      }
    }
  }
}
// menus
$menus: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15;
@each $menu in $menus {
  .menu-item-#{$menu} {
    display: none;
  }
  .dropdown-item-#{$menu} {
    display: none;
  }
}
$menus-more: 16, 17, 18, 19, 20, 21, 22, 23, 24, 25;
@each $menu-more in $menus-more {
  .menu-item-#{$menu-more} {
    display: none;
  }
  .dropdown-item-#{$menu-more} {
    display: block;
  }
}
.menu-item-1 {
  display: list-item;
}
@media(min-width: 25.825rem) {
  .menu-item-2 {
    display: list-item;
  }
}
@media(min-width: 33.825rem) {
  .menu-item-3 {
    display: list-item;
  }
}
@media(min-width: 45.825rem) {
  .menu-item-4 {
    display: list-item;
  }
}
@media(min-width: 50.825rem) {
  .menu-item-5 {
    display: list-item;
  }
}
@media(min-width: 57.825rem) {
  .menu-item-6 {
    display: list-item;
  }
}
@media(min-width: 64.825rem) {
  .menu-item-7 {
    display: list-item;
  }
}
@media(min-width: 70.825rem) {
  .menu-item-8 {
    display: list-item;
  }
}
@media(min-width: 78.825rem) {
  .menu-item-9 {
    display: list-item;
  }
}
@media(min-width: 85.825rem) {
  .menu-item-10 {
    display: list-item;
  }
}
@media(min-width: 95.825rem) {
  .menu-item-11 {
    display: list-item;
  }
}
@media(min-width: 105.825rem) {
  .menu-item-12 {
    display: list-item;
  }
}
@media(min-width: 110.825rem) {
  .menu-item-13 {
    display: list-item;
  }
}
@media(min-width: 115.825rem) {
  .menu-item-14 {
    display: list-item;
  }
}
@media(min-width: 120.825rem) {
  .menu-item-15 {
    display: list-item;
  }
}

// dropdown menu media
@media(max-width: 25.824rem) {
  .dropdown-item-2 {
    display: block;
    // visibility: hidden;
  }
}
@media(max-width: 33.824rem) {
  .dropdown-item-3 {
    display: block;
  }
}
@media(max-width: 45.824rem) {
  .dropdown-item-4 {
    display: block;
  }
}
@media(max-width: 50.824rem) {
  .dropdown-item-5 {
    display: block;
  }
}
@media(max-width: 57.824rem) {
  .dropdown-item-6 {
    display: block;
  }
}
@media(max-width: 64.824rem) {
  .dropdown-item-7 {
    display: block;
  }
}
@media(max-width: 70.824rem) {
  .dropdown-item-8 {
    display: block;
  }
}
@media(max-width: 78.824rem) {
  .dropdown-item-9 {
    display: block;
  }
}
@media(max-width: 85.824rem) {
  .dropdown-item-10 {
    display: block;
  }
}
@media(max-width: 95.824rem) {
  .dropdown-item-11 {
    display: block;
  }
}
@media(max-width: 105.824rem) {
  .dropdown-item-12 {
    display: block;
  }
}
@media(max-width: 110.824rem) {
  .dropdown-item-13 {
    display: block;
  }
}
@media(max-width: 115.824rem) {
  .dropdown-item-14 {
    display: block;
  }
}
@media(max-width: 120.824rem) {
  .dropdown-item-15 {
    display: block;
  }
}
.more-menu {
  display: none;
}