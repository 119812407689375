.ls-team-profile-image-container{
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 130px;
  @media(max-width: 992px){
    width:260px;
    height:auto;
  }
  @media(max-width: 768px){
    width:100%;
    height:auto;
  }
}

.ls-team-profile-img{
  position: absolute;
  top: -72px;
  width: 250px;
  height: 180px;
  @media(max-width: 992px){
    position: relative;
    top:0;
    height:auto;
    margin-bottom:1rem;
    width:auto;
  }
}

.ls-team-profile-container{
  position: relative;
  max-width:300px;
  height: 280px;
  padding:.5rem;
  margin-bottom:3rem;
  @media(max-width:577px){
    height:200px;
  }
}

.ls-team-image-text-container{
  cursor: pointer;
  position: absolute;
  width: 95%;
  padding: .5rem .5rem 0;
  bottom: -26px;
  z-index: 1;
  transition: all 10s linear;
  .comment{
    display: none;
    transition: all 10s linear;
  }
  .replies{
    transition: all 10s linear;
  }
  &:hover .replies{
    transition: all 10s linear;
    display: none;
  }
  &:hover .comment{
    display: inline;
    transition: all 10s linear;
    h5{
      display: block;
      margin: 0 auto;
      width: 93%;
      background: $color-blue-menu;
      text-align: center;
      color: $color-white;
      padding:1.7rem 1rem;
      border-radius: 10px;
      box-shadow: 0px 2px 6px rgba(0,0,0,.4);
      transition: all 10s linear;
      @media(max-width: 577px){
        padding: 1rem;
      }
    }
  }
  @media(max-width:577px){
    bottom:-27px;
  }
  .replies h5{
    display: block;
    margin: 0 auto;
    width: 93%;
    text-align: center;
    background: #fff;
    padding: 1rem 1rem;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0,0,0,.4);
    // transition: all 10s linear;
    @media(max-width: 577px){
      padding: 1rem;
    }
  }
}
.team-img-cont {
  max-height: 230px;
}
.team-text-block {
  min-height: 70px;
  @include transform(translateY(-30px));
  .comment {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-light-blue;
  }
  &:hover {
    .comment {
      display: flex;
    }
  }
}
.team-search-field {
  width: 50%;
  @media(max-width: 992px) {
    width: 100%;
  }
}