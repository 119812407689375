.signup-option {
    border: 2px solid rgba(235, 235, 235, 0.8) !important;
    cursor: pointer;
    @include transition(0.3s ease);
    &:hover {
        border-color: $color-light-blue !important;
    }
    &.active-signup-option {
        border-color: $color-light-blue !important;
        background-color: rgba(76,202,252,0.08);
        .option-icon {
            color: $color-light-blue;
            background-color: #fff;
        }
    }
    h5 {
        font-size: 17px;
        font-family: Poppins-Medium;
    }
    .option-icon {
        background-color: #f2f2f2;
        height: 100px;
        width: 100px;
        font-size: 50px;
    }
}
.ls-login .login-text a {
    color: $color-light-blue;
}