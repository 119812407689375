.animated-filter-bar  {
    flex: 1 1 auto;
    overflow: hidden;
    transition: all 0.5s linear;
    @media(min-width: 992px) {
      flex: .00001;
      height: 54px;
      -webkit-animation: flexGrow 0.5s ease forwards;
      -o-animation: flexGrow 0.5s ease forwards;
      animation: flexGrow 0.5s ease forwards;
    }
    @media(max-width: 991px) {
      max-height: 500px;
    }
  }
  
  .animation-animated {
    @media(min-width: 992px) {
      flex: 1;
      -webkit-animation: flexShrink 0.5s ease forwards;
      -o-animation: flexShrink 0.5s ease forwards;
      animation: flexShrink 0.5s ease forwards;
    }
    @media(max-width: 991px) {
      max-height: 0;
    }
  }
  
  @-webkit-keyframes flexGrow {
    to {
      flex: 1;
      opacity: 1;
    }
  }
  @-o-keyframes flexGrow {
    to {
      flex: 1;
      opacity: 1;
    }
  }
  @keyframes flexGrow {
    to {
      flex: 1;
      opacity: 1;
    }
  }
  
  @-webkit-keyframes flexShrink {
    to {
      flex: .01;
      flex: .00001;
      opacity: 0;
    }
  }
  @-o-keyframes flexShrink {
    to {
      flex: .01;
      flex: .00001;
      opacity: 0;
    }
  }
  @keyframes flexShrink {
    to {
      flex: .01;
      flex: .00001;
      opacity: 0;
    }
  }
  