.ls-filters-bar {
    font-size: 14px;
    @include transition(width 0.5s linear);
    .btn {
        width: 120px;
        height: 36px;
        font-size: 14px;
    }
    .form-control {
        font-size: 14px;
        height: 36px;
        border-color: #ebebeb;
    }
    .input-group-text {
        border-color: #ebebeb;
    }
}
.filter-only-search {
    max-width: 500px;
}
.ls-btn-filters {
    height: 36px;
}
.animated-filter {
    width: 0;
}