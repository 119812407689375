// radio-selction
.custom-form-check {
  padding-left: 0;
  .form-check-label {
    position: relative;
    padding-left: 1.8rem;
    cursor: pointer;
    &:before {
      position: absolute;
      content: "\f111";
      font-family: "Font Awesome 5 Free";
      color: $color-grey;
      font-size: 20px;
      left: 0;
      top: 3px;
      line-height: normal;
    }
  }
  .form-check-input {
    opacity: 0;
  }
  .form-check-input[type=radio]:checked + .form-check-label:before,
  .form-check-input[type=checkbox]:checked + .form-check-label:before {
    content: "\f058";
    font-weight: 900;
    color: $color-light-blue;
  }
}