/* Chat */
.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0;
}
.str-chat.messaging, .str-chat.commerce {
  background-color: transparent;
}
.str-chat__message-text-inner, .str-chat__message-simple-text-inner {
  padding: 1.5rem;
}
.str-chat__li--single .str-chat__message--me .str-chat__message-text-inner {
  border-radius: 25px 25px 0px 25px;
  background-color: rgba(186, 187, 196, 0.1);
}
.str-chat__li--single .str-chat__message-text-inner {
  background-color: rgba(76, 202, 252, 0.1);
  border-radius: 25px 25px 25px 0px;
  border: none;
}
.chat-users-list {
  max-height: 775px;
}
.user-icon {
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
.user-icon img {
  width: 100%;
  height: 100%;
  background-position: cover;
}