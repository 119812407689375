$color-blue: #3d539a;
$color-blue-menu: #4ccafc;
$color-light-blue: #4ccafc;
$color-grey: #999b9f;
$color-red: #ee4d4d;
$color-light-red: #f4434e;
$color-yellow: #F5D925;
$color-green: #7CCC1F;
$color-gold: #FEA917;
$color-white: #fff;
$color-black: #000;
$color-login-blue: #1D3889;
$color-black-heading: #1E1E1E;
$color-placeholder: #BABBC2;
$color-black2: #1D2733;
$color-purple: #9B52E1;

.color-grey {
  color: $color-grey;
}
.color-primary {
  color: $color-blue-menu;
}
.color-black {
  color: $color-black;
}
.color-white {
  color: $color-white;
}
.color-black-heading {
  color: $color-black-heading;
}
.color-gold {
  color: $color-gold;
}
.color-light-red {
	color: $color-light-red;
}
.background-primary {
  background: $color-blue-menu;
}
.color-green {
  color: $color-green;
}
.color-red {
  color: $color-light-red;
}
.color-purple {
  color: $color-purple;
}
.bg-green {
  background-color: $color-green;
}
.bg-red {
  background-color: $color-light-red;
}
.bg-blue {
  background-color: $color-blue-menu;
}
.bg-purple {
  background-color: $color-purple;
}
.link-hover-text {
  color: $color-black2;
  &:hover {
    text-decoration: none;
    color: $color-light-blue;
  }
}