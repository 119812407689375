// Import font family
@font-face {
  font-family: Poppins-Regular;
  src: url('../fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: Poppins-Medium;
  src: url('../fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: Poppins-Bold;
  src: url('../fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url('../fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: BebasNeue-Regular;
  src: url('../fonts/BebasNeue-Regular.ttf');
}

/** Create dynamic font size classes(EX: font-9 for 9px font) & Border Radius Classes(EX border-9 for border Radius for all side 9px)
*/
$sizes: 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30;
@each $size in $sizes {
  .font-#{$size} {
    font-size: $size + px;
  }
  .border-#{$size} {
    border-radius: $size + px;
  }
}

/** Create dynamic font size classes(EX: font-9 for 9px font) & Border Radius Classes(EX border-9 for border Radius for all side 9px)
*/
$sizes: 100, 200, 300, 400, 500, 600, 700 ,800, 900;
@each $size in $sizes {
  .font-weight-#{$size} {
    font-weight: $size !important;
  }
}

#root {
  @extend .ls-height-100;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: Poppins-Regular;
  main {
    flex: 1 1 auto;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-placeholder !important;
    opacity: 1 !important; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color-placeholder !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $color-placeholder !important;
  }
}

.link-text {
  color: $color-light-blue;
  &:hover {
    text-decoration: none;
    color: darken($color-light-blue, 3%);
  }
}

button, .btn {
  &:focus,
  &:active,
  &:active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    outline: 0;
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    cursor: url('../images/ban.png'), auto;	
  }
}

.family-poppins-bold {
  font-family: Poppins-Bold;
}

.family-poppins-semibold {
  font-family: Poppins-SemiBold;
}

.family-poppins-medium {
  font-family: Poppins-Medium;
}

.family-bebas-nueue {
  font-family: BebasNeue-Regular;
}

// Overflow Hidden
.ls-overflow-hidden{
  overflow: hidden!important;
}

// Height 100%
.ls-height-100{
  height: 100%!important;
}

.ls-position-relative{
  position: relative;
}
// Objecct Fit Cover
.ls-object-cover{
  object-fit: cover!important;
}
.ls-form-grey {
  background: #F9F9FC;
  color:#000;
}
.ls-form-grey {
  background: #F9F9FC!important;
  border: none;
  color:#000!important;
  padding:1.5rem 1rem;
  &::placeholder {
    color: #000;
    opacity: 1; /* Firefox */
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #000;
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
   color: #000;
  }
  &:focus{
    background: #F9F9FC;
    color:#000;
  }
}
.ls-form-choose-grey {
  background: #F9F9FC!important;
  border: none;
  color:#000!important;
  padding:1.5rem 1rem;
  &:after{
    content: 'Upload'!important;
    height:100%;
    display: flex;
    align-items: center;
    padding:1.5rem 2.5rem!important;
    background:#1D3889;
    color:#fff;
    border-radius:5px;
  }
  &::placeholder {
    color: #000;
    opacity: 1; /* Firefox */
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #000;
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
   color: #000;
  }
  &:focus{
    background: #F9F9FC;
    color:#000;
  }
}

input.form-control {
  &:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
}

// Dropdown
.link-staff-dropdown {
  color: $color-grey!important;
  min-width: 209px;
}
.link-staff-dropdown-sm {
  min-width: 169px;
}

// Buttons
.lS-btn-green-style {
  background-color: $color-green!important;
  border-color: $color-green!important;
  &:focus,
  &:active,
  &:hover {
    background-color: darken($color-green, 3%)!important;
    border-color: $color-green!important;
  }
}
.lS-btn-gold-style {
  background-color: $color-gold!important;
  border-color: $color-gold!important;
  &:focus,
  &:active,
  &:hover {
    background-color: darken($color-gold, 3%)!important;
    border-color: $color-gold!important;
  }
}
.lS-btn-red-style {
  background-color: $color-light-red!important;
  border-color: $color-light-red!important;
  &:focus,
  &:active,
  &:hover {
    background-color: darken($color-light-red, 3%)!important;
    border-color: $color-light-red!important;
  }
}
.lS-btn-yellow-style {
  background-color: $color-yellow!important;
  border-color: $color-yellow!important;
  &:focus,
  &:active,
  &:hover {
    background-color: darken($color-yellow, 3%)!important;
    border-color: $color-yellow!important;
  }
}
.lS-btn-blue-style {
  background-color: $color-blue-menu!important;
  border-color: $color-blue-menu!important;
  color: $color-white;
  &:focus,
  &:active,
  &:hover {
    color: $color-white;
    background-color: darken($color-blue-menu, 3%)!important;
    border-color: $color-blue-menu!important;
  }
}
.lS-btn-grey-style {
  background-color: $color-grey!important;
  border-color: $color-grey!important;
  color: $color-white;
  &:focus,
  &:active,
  &:hover {
    color: $color-white;
    background-color: darken($color-grey, 3%)!important;
    border-color: $color-grey!important;
  }
}
button.lS-btn-blue {
  width: 124px;
  height: 34px;
  @extend .font-12;
  @extend .lS-btn-blue-style;
}
button.lS-btn-blue-large,
.ls-btn-link.lS-btn-blue-large {
  width: 200px;
  height: 46px;
  @extend .font-16;
  @extend .lS-btn-blue-style;
}
button.lS-btn-grey-large,
.ls-btn-link.lS-btn-grey-large {
  width: 200px;
  height: 46px;
  @extend .font-16;
  @extend .lS-btn-grey-style;
}
.ls-btn-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
 }
button.lS-btn-red {
  width: 124px;
  height: 34px;
  @extend .font-12;
  @extend .lS-btn-red-style;
}

// icon buttons
.lS-btn-icon-sm {
  text-align: center;
  height: 30px;
  width: 30px;
  flex: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 12px;
}
.lS-btn-icon {
  height: 30px;
  width: 30px;
  flex: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 12px;
  @include border-radius(5px);
  &:disabled {
    background-color: #999 !important;
    border-color: #999 !important;
  }
}
.btn-unlike {
  background-color: #ccc !important;
  border-color: #ccc !important;
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    background-color: #bbb !important;
    border-color: #bbb !important;
  }
}
.lS-btn-icon-md {
  height: 44px;
  width: 44px;
  flex: 0 0 44px;
}
.lS-btn-icon-lg {
  height: 56px;
  width: 56px;
  flex: 0 0 56px;
}
.ls-btn-icon-styling-sm {
  padding:.3rem .5rem;
}
.lS-btn-sm {
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
// pagination
.ls-pagination {
  .page-item {
    .page-link {
      @extend .font-12;
      color: #000;
    }
  }
  .page-item.active {
    .page-link {
      @extend .lS-btn-blue-style;
      color: #fff;
    }
  }
}

// Modal Designs
.ls-modal-curve,
.modal-content {
  border-radius: 12px;
  .modal-header {
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
  }
  .modal-footer {
    .btn {
      font-size: 15px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}

.lS-card-radius {
  @include border-radius(10px);
}
// min-width0
.min-width0 {
  min-width: 0;
}

.lS-card-shadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}

.lS-footer {
  background-color: #f0f0f0;
  a {
    &:hover {
      text-decoration: none;
      color: #000;
    }
  }
}

// form
.ls-form {
  .form-control,
  .custom-file-label {
    background-color: #F9F9FC;
    border-color: #F4F4F6;
    @include border-radius(6px);
    font-size: 18px;
    font-family: Poppins-Medium;
    padding: 1.785rem 1rem;
  }
  .form-input-white .form-control {
    background-color: #fff;
  }
  .custom-file-label {
    line-height: 0;
    &:after {
      padding: 1.785rem 3rem;
      color: $color-white;
      background-color: $color-blue;
      line-height: 0;
      @include border-radius(6px);
      @media(max-width: 1199px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
  .custom-file-input:lang(en)~.custom-file-label::after {
    content: "Upload";
  }
  .form-control:disabled {
    opacity: 0.4;
    background-color: #F6F6F6;
  }
  textarea.form-control {
    resize: none;
    padding-top: 0.785rem;
    padding-bottom: 0.785rem;
  }
  .custom-file {
    padding: 1.785rem 1rem;
    z-index: 0;
  }
}
.input-with-icon {
  background-image: url('../images/trainings/clock.png');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: right 1rem center;
}
.text-black {
  color: $color-black-heading;
}
.ls-border-color {
  border-color: rgba(235, 235, 235, 0.5) !important;
}

.error-msg {
  color: red;
  font-size: 11px;
  margin-bottom: 10px;
}

// page title bar
.page-title-bar {
  @include border-radius(8px);
  @include box-shadow(0 4px 10px rgba(128, 128, 128, 0.05));
  h4 {
    font-size: 26px;
  }
  #search-form {
    .serach-icon {
      width: 2.375rem;
      line-height: 2.375rem;
      text-align: center;
      pointer-events: none;
      color: #aaa;
      font-size: 14px;
      margin-right: -14px;
      font-weight: bold;
    }
    .search-input {
      font-size: 14px;
      margin: 2px;
    }
    .search-input:focus {
      outline: 0;
      box-shadow: none;
    }
    .search-bar {
      border: 2px solid lightgrey;
      border-radius: 5px;
      input {
        border: 0;
      }
    }
    .search-panel {
      width: 100px;
    }
    .search-panel::before {
      content: '';
      border-left: 1px solid #d2d7da;
      padding-bottom: 6px;
    }
    .dropdown-toggle::after {
      display: none !important;
    }

    .search-panel-dropdown .fas { 
      margin: 0px 6px;
      font-weight: bold;
      font-size: 14px;
      display: inline-block;
      vertical-align: middle;
    } 
    .search-panel-dropdown {
      width: 100%;
      font-size: 14px;
      margin-top: 3px;
      color: #aaa;
      // margin-left: 5px;
    }
    .search-panel-dropdown:focus {
      box-shadow: none;
      outline: 0;
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color-placeholder;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $color-placeholder;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: $color-placeholder;
    }
  }
}

// radius
.radius-8 {
  @include border-radius(8px);
}
.radius-10 {
  @include border-radius(10px);
}

// table
.ls-table {
  max-height: 500px;
  th {
    font-size: 14px;
    font-weight: normal;
    font-family: Poppins-Medium;
    border-top: 0;
    vertical-align: middle;
  }
  .table {
    table-layout: fixed;
    thead th {
      border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    }
    td {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      font-size: 14px;
      color: $color-grey;
      vertical-align: middle;
    }
    tbody tr:last-child td {
      padding-bottom: 0 !important;
    }
  }
}
.table-rating {
  width: 54px;
}
.cursor-pointer {
  cursor: pointer;
}
.link-hover {
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
    background-color: rgba(76, 202, 252, 0.04);
  }
}

// Loader
.ls-loader {
  .spinner-border {
    width: 2rem;
    height: 2rem;
    border-color: $color-light-blue;
    border-right-color: transparent;
  }
}
.more-text {
	position: absolute;
	right: 0;
  background-color: $color-white;
  font-style: italic;
	@extend .font-12;
	color: $color-light-blue;
  top: 3px;
  &:hover {
    color: darken($color-light-blue, 5%);
  }
}
.thumb-wrap {
  width: 230px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 230px;
  overflow: hidden;
  // border: 1px solid rgba(235, 235, 235, 0.5);
  background-color: #FCFCFC;
  img {
      max-width: 100%;
  }
  @media(max-width: 991px) {
      width: 180px;
      height: 150px;
      flex: 0 0 180px;
  }
}
.thumb-wrap-sm {
  width: 54px;
  height: 54px;
  flex: 0 0 54px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.thumb-wrap-lg {
  width: 100%;
  flex: 0 0 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.thumb-wrap-xl {
    width: 400px;
    height: 200px;
    flex: 0 0 400px;
    @media(min-width: 1199px) {
      min-height: 300px;
    }
    @media(min-width: 768px) {
      height: 100%;
      max-height: 400px;
    }
    @media(max-width: 1199px) {
      width: 300px;
      flex: 0 0 300px;
    }
    @media(max-width: 767px) {
      width: 100%;
      flex: 0 0 100%;
    }
}
.thumb-wrap-sq {
	height: 150px;
	width: 150px;
	flex: 0 0 150px;
	@include border-radius(10px);
	@media(max-width: 991px) {
		height: 70px;
		width: 70px;
		flex: 0 0 70px;
	}
}
.thumb-wrap-100 {
  width: 100px;
  height: 100px;
  flex: 0 0 100px;
}
.thumb-wrap-full {
  width: 100%;
  height: 230px;
  flex: 0 0 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.flex-50 {
  width: 50%;
  flex: 0 0 50%;
}
.flex-sm-50 {
  @media(min-width: 576px) {
    width: 50%;
    flex: 0 0 50%;
  }
}
.line-height-common {
  line-height: 1.5;
}
.line-height-normal {
  line-height: normal;
}
.img-object-fit {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
// action-buttons
.action-buttons {
  .lS-btn-sm {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
  }
}
.height-auto {
  height: auto !important;
  line-height: 1.5 !important;
}
.flex-equal-width {
  flex: 1 1 0px;
}
.back-btn {
  position: absolute;
  top: 0.8rem;
  left: 1.25rem;
}
.selectImgCross {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #fff;
  height: 20px;
  width: 20px;
  background: #000;
  border-radius: 50%;
  text-align: center;
  line-height: 1.1;
  cursor: pointer;
  border: 1px solid #fff;
}

.box-cols {
  border: 3px solid transparent;
  &.active-box-col,
  &:hover {
    border-color: $color-light-blue;
  }
}
.btn-load-more {
  border: 1px solid $color-light-blue;
  color: $color-light-blue;
  &:hover {
    background-color: $color-light-blue;
    color: $color-white;
  }
}
.text-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
