.course-icon-col {
  height: 49px;
  width: 49px;
  font-size: 23px;
}
.custom-bar {
  height: 7px;
}
.graph-col {
  height: 280px;
  max-height: 280px;
}

.bar-blue {
  .progress-bar {
    background-color: $color-blue-menu;
  }
}
.bar-yellow {
  .progress-bar {
    background-color: $color-yellow;
  }
}
.bar-green {
  .progress-bar {
    background-color: $color-green;
  }
}
.bar-red {
  .progress-bar {
    background-color: $color-red;
  }
}