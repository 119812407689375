.tile-icon {
    flex: 0 0 150px;
    width: 150px;
    height: 150px;
    background-color: #f2f2f2;
    overflow: hidden;
    img {
        max-width: 100%;
    }
}
.tile-icon-sm {
    flex: 0 0 128px;
    width: 128px;
    height: 128px;
}
.tile-count {
    font-size: 85px;
    color: $color-light-blue;
}
.interview-tile {
    min-height: 140px;
}
.detail-tile-dotted {
    border: 1px dashed #BBBBBB;
}
.tile-thumbs img {
    max-width: 100%;
    max-height: 120px;
    @include border-radius(10px);
}
.custom-col-width {
    @media(min-width: 1200px) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}