.event-calendar-sm {
	@include border-radius(10px);
	border: 0;
	.fc {
		.fc-day-grid-event .fc-content {
			font-size: 11px;
		}
		.fc-row {
			.fc-content-skeleton {
				padding-top: 10px;
				td {
					text-align: center;
				}
			}
		}
		.fc-head-container {
			.fc-row.fc-widget-header {
				border-bottom: 1px solid rgba(31, 60, 83, 0.08);
				padding: 15px;
				margin-bottom: 10px;
			}
		}
		.fc-sun {
			color: $color-light-red;
		}
		.fc-other-month {
			opacity: 0.5;
		}
		.fc-today {
			background-color: transparent;
			color: $color-light-blue;
		}
		.fc-scroller.fc-day-grid-container {
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 5px;
			height: auto !important;
		}
		.fc-day-header {
			font-family: Poppins-Medium;
			font-weight: normal;
		}
		.fc-toolbar {
			h2 {
				color: $color-light-blue;
				font-family: Poppins-Semibold;
				font-size: 18px;
			}
		}
		.fc-header-toolbar {
			margin-bottom: 0;
			border-bottom: 1px solid rgba(31, 60, 83, 0.08);
			padding: 16px 20px;
			.fc-button-primary {
				background-color: transparent;
				border: 0;
				color: $color-light-blue;
				font-size: 12px;
			}
		}
		.fc-day-grid {
			.fc-row {
				// height: 42px !important;
				min-height: 3em;
			}
		}
		.fc-dayGrid-view .fc-day-top .fc-day-number {
			float: none;
		}
	}
	.fc-unthemed {
		th, td {
			border: 0;
		}
	}
}
.calendar-no-event-list {
	.fc .fc-scroller.fc-day-grid-container {
	}
}